import {
  ToolbarMenuItemShape,
  ToolbarMenuSubItem,
} from "@casasoft/styleguide/components/toolbar";
import { hasAccessTo } from "utilities/featureFlags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBell,
  faCalendar,
  faCog,
  faExternalLink,
  faFileInvoice,
  faLightbulbOn,
  faLockAlt,
  faPlug,
  faQuestionCircle,
  faSignOut,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { ReactComponent as TicketIcon } from "assets/ticket.svg";
import { ReactComponent as AIChatBotIcon } from "assets/aIChatBot.svg";
import { useNotificationsCounter } from "entities/event/notificationStore";
import { logOut } from "utilities/auth";
import Config from "config";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getCasaoneMarketplaceIntegrationLink } from "../helpers/getCasaoneMarketplaceIntegrationLink";
import { useAppSelector } from "redux/hooks";
import {
  selectContactLang,
  selectProfile,
  selectProfileAvatar,
} from "redux/auth/selectors";
import { Media } from "utilities";
import { ToggleState } from "../../App";

interface UseToolbarItemsProps {
  invoicesCount: number;
  faqToggleState: ToggleState;
  setFaqToggleState: (state: ToggleState) => void;
  notificationCenterToggleState: ToggleState;
  setNotificationCenterToggleState: (state: ToggleState) => void;
}

export const useToolbarItems = ({
  invoicesCount,
  faqToggleState,
  setFaqToggleState,
  notificationCenterToggleState,
  setNotificationCenterToggleState,
}: UseToolbarItemsProps) => {
  const { t, i18n } = useTranslation();

  const alertCount = useNotificationsCounter();
  const avatar = useAppSelector(selectProfileAvatar);
  const profile = useAppSelector(selectProfile);
  const isCrm =
    profile?.data?.company?.id === "casasoft" &&
    profile?.data?.apiCompanySection === "casasoft";
  const lang = useAppSelector(selectContactLang);

  const faqSubItems: (ToolbarMenuSubItem | false)[] = [
    {
      key: "visitHelpcenter",
      action: {
        onItemClick: () => {
          setFaqToggleState(faqToggleState === "open" ? "peek" : "open");
          setNotificationCenterToggleState("close");
        },
        onItemEnter: () => {
          if (faqToggleState !== "open") {
            setFaqToggleState("peek");
          }
        },
        onItemLeave: () => {
          if (faqToggleState !== "open") {
            setFaqToggleState("close");
          }
        },
      },
      icon: <FontAwesomeIcon fixedWidth icon={faLightbulbOn} />,
      label: t("Visit Helpcenter"),
      suffixIcon: (
        <span style={{ top: "-0.1rem", position: "relative" }}>
          <FontAwesomeIcon
            fixedWidth
            opacity={0.4}
            icon={faAngleRight}
            size="xs"
          />
        </span>
      ),
    },
    {
      key: "submitTicket",
      action: {
        externalLink: i18n.exists("submitTicketUrl")
          ? t("submitTicketUrl")
          : "https://casasoft.ch/support",
      },
      icon: (
        <span className="tw-inline-block tw-h-[20px] tw-leading-5 tw-align-middle">
          <TicketIcon width={19} height={20} />
        </span>
      ),
      suffixIcon: (
        <span style={{ top: "-0.1rem", position: "relative" }}>
          <FontAwesomeIcon
            fixedWidth
            opacity={0.4}
            icon={faExternalLink}
            size="xs"
          />
        </span>
      ),
      label: t("Submit a ticket"),
    },
    {
      key: "virtualAssistant",
      action: {
        externalLink: i18n.exists("virtualAssistantUrl")
          ? t("virtualAssistantUrl")
          : "https://casasoft.ch/chatbase",
      },
      icon: (
        <span className="tw-inline-block tw-h-[20px] tw-leading-5 tw-align-middle">
          <AIChatBotIcon width={19} height={20} />
        </span>
      ),
      suffixIcon: (
        <span style={{ top: "-0.1rem", position: "relative" }}>
          <FontAwesomeIcon
            fixedWidth
            opacity={0.4}
            icon={faExternalLink}
            size="xs"
          />
        </span>
      ),
      label: t("Ask virtual assistant"),
    },
  ];

  const settingsItem: ToolbarMenuSubItem | null = {
    key: "settings",
    action: { navLink: "/admin" },
    icon: <FontAwesomeIcon fixedWidth icon={faCog} />,
    label: t("Settings"),
  };

  const logoutItem: ToolbarMenuSubItem | null = {
    key: "logout",
    action: {
      onItemClick: () => {
        logOut();
      },
    },
    icon: <FontAwesomeIcon fixedWidth icon={faSignOut} />,
    label: t("Logout"),
  };
  const securitySettingsItem: ToolbarMenuSubItem | null = {
    key: "securitySettings",
    action: {
      externalLink: `${Config.casaconnectUrl}/realms/casasoft/account/password?kc_locale=${lang}`,
    },
    icon: <FontAwesomeIcon fixedWidth icon={faLockAlt} />,
    suffixIcon: (
      <span style={{ top: "-0.1rem", position: "relative" }}>
        <FontAwesomeIcon
          fixedWidth
          opacity={0.4}
          icon={faExternalLink}
          size="xs"
        />
      </span>
    ),
    label: t("Security"),
  };

  const invoiceItem: ToolbarMenuSubItem | null =
    hasAccessTo("invoiceManagement") && !isCrm
      ? {
          key: "invoices",
          action: { navLink: "/invoices" },
          label: t("Invoices"),
          icon: (
            <span className="tw-relative">
              <FontAwesomeIcon fixedWidth icon={faFileInvoice} />
              {/* Uncomment to bring badge icon back */}
              {/* {invoicesCount >= 1 && (
                <span className="tw-absolute tw--mt-3 tw-translate-x-1/4 tw-top-1/2 fa-layers-counter tw-font-semibold tw-scale-50 xxxtw-translate-x-1/4 tw-bg-cs-danger">
                  {invoicesCount}
                </span>
              )} */}
            </span>
          ),
        }
      : null;

  const casaoneMarketplaceIntegrations: ToolbarMenuSubItem = {
    key: "casaone-marketplace-integrations",
    action: { externalLink: getCasaoneMarketplaceIntegrationLink(lang) },
    label: t("Integration marketplace"),
    icon: <FontAwesomeIcon fixedWidth icon={faPlug} />,
    suffixIcon: (
      <span style={{ top: "-0.1rem", position: "relative" }}>
        <FontAwesomeIcon
          fixedWidth
          opacity={0.4}
          icon={faExternalLink}
          size="xs"
        />
      </span>
    ),
  };

  const menuIcon = avatar ? (
    <div
      style={{
        position: "relative",
        width: 42,
        height: 42,
      }}
      className="tw-relative"
    >
      <img
        style={{ verticalAlign: "baseline" }}
        width="42"
        height="42"
        alt="avatar"
        className="tw-rounded-full"
        src={Media.mediaThumbnail(avatar, "sq")}
      />
      {invoicesCount >= 1 && hasAccessTo("invoiceManagement") && (
        <span className="tw-absolute tw--mt-3 tw-translate-x-1/4 tw-top-1/2 fa-layers-counter tw-font-semibold tw-scale-50 xxxtw-translate-x-1/4 tw-bg-cs-danger">
          {invoicesCount}
        </span>
      )}
    </div>
  ) : (
    <span className="tw-relative">
      <FontAwesomeIcon fixedWidth icon={faUser} />
      {invoicesCount >= 1 && hasAccessTo("invoiceManagement") && (
        <span className="tw-absolute tw--mt-3 tw-translate-x-1/4 tw-top-1/2 fa-layers-counter tw-font-semibold tw-scale-50 xxxtw-translate-x-1/4 tw-bg-cs-danger">
          {invoicesCount}
        </span>
      )}
    </span>
  );

  const toolbarItems: {
    [key in "faq" | "calendar" | "notifications" | "settingsDropdown"]:
      | ToolbarMenuItemShape
      | false;
  } = {
    faq: hasAccessTo("basicCasaoneFeatures") && {
      key: "toolbar-item-faq",
      icon: (
        <span className="tw-relative">
          <FontAwesomeIcon fixedWidth icon={faQuestionCircle} />
        </span>
      ),
      label: t("Helpcenter"),
      action: {
        subItems: faqSubItems,
      },
    },
    calendar: hasAccessTo("eventManagement") && {
      key: "toolbar-item-calendar",
      action: { navLink: "/plan/events" },
      icon: <FontAwesomeIcon fixedWidth icon={faCalendar} />,
      label: t("Calendar"),
    },
    notifications: hasAccessTo("basicCasaoneFeatures") && {
      key: "toolbar-item-notifications",
      icon: (
        <span className="tw-relative">
          <FontAwesomeIcon fixedWidth icon={faBell} />
          {alertCount >= 1 && (
            <span className="tw-absolute tw--mt-3 tw-translate-x-1/4 tw-top-1/2 fa-layers-counter tw-font-semibold tw-scale-50 xxxtw-translate-x-1/4 tw-bg-cs-danger">
              {alertCount}
            </span>
          )}
        </span>
      ),
      label: t("Notifications"),
      action: {
        onItemClick: () => {
          setNotificationCenterToggleState(
            notificationCenterToggleState === "open" ? "peek" : "open"
          );
          setFaqToggleState("close");
        },
        onItemEnter: () => {
          if (notificationCenterToggleState !== "open") {
            setNotificationCenterToggleState("peek");
          }
        },
        onItemLeave: () => {
          if (notificationCenterToggleState !== "open") {
            setNotificationCenterToggleState("close");
          }
        },
      },
    },
    settingsDropdown: {
      key: "toolbar-item-settings-dropdown",
      label: t("Menu"),
      action: {
        subItems: [
          settingsItem,
          securitySettingsItem,
          invoiceItem,
          casaoneMarketplaceIntegrations,
          logoutItem,
        ],
      },
      icon: menuIcon,
    },
  };
  return {
    toolbarItems,
    invoiceItem,
    menuIcon,
    securitySettingsItem,
    logoutItem,
    settingsItem,
  };
};
