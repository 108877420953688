import { useState, useEffect, Fragment, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { ToolbarMenuItemAction } from "./ToolbarMenuItem";

type TollbarDropdownItem =
  | {
      key: string;
      label: string;
      icon: ReactNode;
      suffixIcon?: ReactNode;
      action: ToolbarMenuItemAction;
    }
  | null
  | false;

export interface ToolbarMenuItemDropdownProps {
  items?: TollbarDropdownItem[];
  id?: string;
  children?: ReactNode;
}

const ToolbarMenuItemDropdown = ({
  items,
  id,
  children,
}: ToolbarMenuItemDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [buttonBlurTimer, setButtonBlurTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (buttonBlurTimer) {
        clearTimeout(buttonBlurTimer);
      }
    };
  }, [buttonBlurTimer]);

  return (
    <>
      <button
        id={id}
        type="button"
        className={`
          tw-flex tw-items-center tw-h-12 tw-text-inherit tw-px-4 tw-whitespace-nowrap tw-text-lg
        `}
        onClick={() => {
          setDropdownOpen((prev) => !prev);
        }}
        onBlur={() => {
          setButtonBlurTimer(
            setTimeout(() => {
              setDropdownOpen(() => false);
            }, 500)
          );
        }}
        aria-haspopup="true"
        aria-expanded={dropdownOpen}
      >
        {children}
      </button>
      <ol
        className={`tw-absolute tw-top-12 tw-right-0 tw-overflow-hidden tw-pl-0 tw-list-none tw-text-base tw-text-white tw-pointer-events-none tw-transition-all ${
          dropdownOpen
            ? "tw-opacity-100 tw-pointer-events-auto"
            : "tw-opacity-0"
        }`}
        aria-hidden={!dropdownOpen}
      >
        {items
          ?.filter((elem) => !!elem)
          .map((elem, i) => {
            let durationClass = "tw-duration-200";
            if (i > 0) {
              durationClass = "tw-duration-300";
            }
            if (i > 1) {
              durationClass = "tw-duration-500";
            }
            if (i > 2) {
              durationClass = "tw-duration-700";
            }

            function renderSubActionItem() {
              if (!elem) {
                throw new Error("An error occured");
              }
              const iconLabel = (
                <>
                  <span>
                    <span className="tw-mr-2">{elem.icon}</span>
                    {elem.label}
                  </span>
                  {elem.suffixIcon && (
                    <span className="tw-ml-2">{elem.suffixIcon}</span>
                  )}
                </>
              );
              const action = elem.action;
              if ("navLink" in action) {
                return (
                  <NavLink
                    className={`
                    tw-bg-cs-shade-700 tw-text-white tw-block tw-pl-4 tw-pr-4 tw-transition-all tw-whitespace-nowrap tw-leading-8 hover:tw-bg-cs-shade-800 hover:tw-no-underline hover:tw-pl-5 hover:tw-pr-3
                    ${durationClass}
                    ${
                      dropdownOpen
                        ? "tw-translate-x-0 tw-scale-100"
                        : "tw-translate-x-4 tw-scale-90"
                    }
                  `}
                    to={action.navLink}
                  >
                    {iconLabel}
                  </NavLink>
                );
              }
              if ("externalLink" in action) {
                return (
                  <a
                    className={`
                    tw-bg-cs-shade-700 tw-text-white tw-block tw-pl-4 tw-pr-4 tw-transition-all tw-whitespace-nowrap tw-leading-8 hover:tw-bg-cs-shade-800 hover:tw-no-underline hover:tw-pl-5 hover:tw-pr-3
                    ${durationClass}
                    ${
                      dropdownOpen
                        ? "tw-translate-x-0 tw-scale-100"
                        : "tw-translate-x-4 tw-scale-90"
                    }
                  `}
                    href={action.externalLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {iconLabel}
                  </a>
                );
              }

              return (
                <button
                  type="button"
                  className={`
                  tw-min-w-full tw-bg-cs-shade-700 tw-text-white tw-block tw-pl-4 tw-pr-4 tw-transition-all tw-whitespace-nowrap tw-leading-8 hover:tw-bg-cs-shade-800 hover:tw-no-underline hover:tw-pl-5 hover:tw-pr-3
                  ${durationClass}
                  ${
                    dropdownOpen
                      ? "tw-translate-x-0 tw-scale-100"
                      : "tw-translate-x-4 tw-scale-90"
                  }
                `}
                  onClick={() => {
                    action.onItemClick();
                  }}
                  onMouseEnter={() => {
                    action.onItemEnter?.();
                  }}
                  onMouseLeave={() => {
                    action.onItemLeave?.();
                  }}
                >
                  {iconLabel}
                </button>
              );
            }

            if (!elem) {
              return <Fragment key={i} />;
            }
            return (
              <li
                key={elem.key}
                data-toolbar-dropdown-item-key={elem.key}
                className={elem.action.className}
              >
                {renderSubActionItem()}
              </li>
            );
          })}
      </ol>
    </>
  );
};

export default ToolbarMenuItemDropdown;
